import React, { useState, useEffect } from "react";
import "./Loader.css";
import { Box } from "@mui/material";
import { ReactComponent as BrandLogo } from "../../assets/images/logo.svg";
import { LinearProgress } from "@mui/material";

const Loader = (props) => {
  const [loadingText, setLoadingText] = useState(
    props.treeGeneratingLoader
      ? props?.type === "treeCreating"
        ? "Analyzing the Event..."
        : "Loading..."
      : `${props.alreadyExistsPlan ? "Loading" : "Creating"} Action Plan...`
  );
  const textLoadingTree = [
    "Analyzing the Event...",
    "Contextualizing Impact...",
    "Unveiling Pathways...",
    "Exploring Ripple Effects...",
    "Expanding Effects...",
    "Formulating Outcomes...",
    "Finalizing Decision Tree...",
  ];
  const textLoadingPlan = ["Creating Action Plan..."];
  const normalTextLoading = ["Loading..."];

  useEffect(() => {
    let currentIndex = 0;
    const textArray = props.treeGeneratingLoader
      ? props?.type === "treeCreating"
        ? textLoadingTree
        : normalTextLoading
      : textLoadingPlan;
    const intervalId = setInterval(() => {
      setLoadingText(textArray[currentIndex]);

      currentIndex += 1;

      if (currentIndex === textArray.length) {
        clearInterval(intervalId);
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [props.treeGeneratingLoader]);

  return (
    <Box
      className={
        props.containerClassName
          ? "progress-" + props.containerClassName
          : "progress-container"
      }
    >
      {props.treeGeneratingLoader || props.planGeneratingLoader ? (
        loadingText
      ) : (
        <Box>
          <BrandLogo style={{ maxWidth: "50vw" }} />
        </Box>
      )}
      <LinearProgress
        variant="indeterminate"
        sx={{
          marginTop: "3px",
          width: "inherit",
          maxWidth: "50vw",
          backgroundColor: "#a4a4a450",
          borderRadius: "2px",
        }}
      />
      {props?.type === "treeCreating" && (
        <Box
          sx={{
            fontSize: "12px",
            marginTop: 1,
            fontFamily: "ClashDisplay-Regular",
            textAlign: "center",
          }}
        >
          This can take 3 mins. Please do not refresh.
        </Box>
      )}
    </Box>
  );
};

export default Loader;
